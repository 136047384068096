<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
      <a-card>
        <template slot="title">
          <strong class="float-right">Laporan Mutasi Tabungan Harian</strong>
        </template>
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <label>Antara Tanggal: </label>
            <a-date-picker
              class="ml-2"
              style="width: 100px;"
              v-model="tgl1"
              :allowClear="false"
              format="DD-MM-YYYY"
              @change="(dateMoment, dateString) => changePeriode(dateMoment, dateString, 'tgl1')"
            />
            <label class="ml-3">s.d: </label>
            <a-date-picker
              class="ml-2 mr-2"
              style="width: 100px;"
              v-model="tgl2"
              :allowClear="false"
              format="DD-MM-YYYY"
              @change="(dateMoment, dateString) => changePeriode(dateMoment, dateString, 'tgl2')"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <label>Golongan Tab: </label>
            <a-select style="width: 300px;">
              <a-select-option></a-select-option>
            </a-select>
            <label class="ml-3">s.d: </label>
            <a-select style="width: 300px;">
              <a-select-option></a-select-option>
            </a-select>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <label>Kelompok: </label>
            <a-select style="width: 100px;">
              <a-select-option></a-select-option>
            </a-select>
            <label class="ml-3">s.d: </label>
            <a-select style="width: 100px;">
              <a-select-option></a-select-option>
            </a-select>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tgl1: null,
      tgl2: null,
      goltab1: null,
      goltab2: null,
      kelompok1: null,
      kelompok2: null,
      perao: false,
      cabang: null,
      dengankelompok: false,
    }
  },
}
</script>

<style lang="scss" scoped></style>
